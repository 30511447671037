import React, { PureComponent } from 'react'
import Styled from 'styled-components'
import Point from '../point';

const SideView = Styled.div`
    flex:1;
    display: flex;
    background-color: ${props => props.color};
    align-items: center;
    justify-content: center;
`

export default class Side extends PureComponent {
    render() {
        const { color, point } = this.props
        return (
            <SideView color={color}>
                <Point value={point} />
            </SideView>
        )
    }
}
