import React, { PureComponent } from 'react'
import Styled from 'styled-components'

const Container = Styled.div`
    color: white;
    font-size: 12em;
    transform: rotate(-90deg);
`

export default class Point extends PureComponent {

    static defaultProps = {
        value: {}
    }

    render() {
        const { value } = this.props
        const num = Object.keys(value).length
        return (
            <Container>
                {num}
            </Container>
        )
    }
}
