import React, { PureComponent } from 'react'
import Styled from 'styled-components'
import Timer from '../timer';


const Container = Styled.div`
    position: absolute;
    right: 30px;
    top:50%;
    left:30px;
    display: flex;
    transform: translateY(-50%);
    flex-direction: row;
    align-items:center;
    justify-content: space-between;
`

const Btn = Styled.div`
    font-size: 1em;
    height: 66px;
    display: flex;
    background: white;
    align-items:center;
    text-align: center;
    justify-content: center;
    padding: 15px;
    border-radius: 50%;
    width: 66px;
    transform: rotate(-90deg);
    box-shadow: 0 10px 15px rgba(0,0,0,0.3);
    user-select: none;
`

export default class Actions extends PureComponent {

    onStart = () => {
        const { onClick } = this.props
        this.timer.start()
        onClick()
    }

    render() {
        const { onUndo } = this.props
        return (
            <Container>
                <Btn onClick={this.onStart}>RESTART</Btn>
                <Timer ref={ref => this.timer = ref} />
                <Btn onClick={onUndo}>UNDO</Btn>
            </Container>
        )
    }
}
