import React, { Component } from 'react';
import './App.css';
import Side from './components/side';
import Styled from 'styled-components'
import Actions from './components/actions';
import firebase from 'firebase/app'
import 'firebase/database'


const config = {
  apiKey: "AIzaSyAGg_eo50VUYcAeSFNSo70mAMruQ17Ufgs",
  authDomain: "biliardino-43082.firebaseapp.com",
  databaseURL: "https://biliardino-43082.firebaseio.com",
  projectId: "biliardino-43082",
  storageBucket: "biliardino-43082.appspot.com",
  messagingSenderId: "776645828966"
}

firebase.initializeApp(config);

const Container = Styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  flex-direction: column;
`

class App extends Component {

  state = {
    points: {
      red: {},
      blu: {}
    },
    last: ''
  }

  componentDidMount = () => {

    firebase.database()
      .ref('/temp')
      .on('value', (res) => {

        const value = res.val() || {}
        const { points } = this.state

        const last = Object.keys(points.blu || {}).length !== Object.keys(value.blu || {}).length
          ? 'blu'
          : Object.keys(points.red || {}).length !== Object.keys(value.red || {}).length ? 'red' : ''

        this.setState({
          points: {
            blu: value.blu || {},
            red: value.red || {},
          },
          last
        })

      })

  }

  resetPoints = () => {
    firebase.database().ref('/temp').set({ red: {}, blu: {} })
  }

  undo = () => {
    const { last, points } = this.state
    if (!last) return

    const removeLast = Object.keys(points[last])[0]
    const filtered = { ...points[last] }
    delete filtered[removeLast]

    firebase.database()
      .ref('/temp')
      .child(last)
      .set(filtered).then(() => {
        this.setState({ last: '' })
      })

  }

  render() {
    const { points } = this.state
    return (
      <Container>
        <Side color="#4858FD" point={points.blu} />
        <Actions onClick={this.resetPoints} onUndo={this.undo} />
        <Side color="#FD303C" point={points.red} />
      </Container>
    )
  }
}

export default App;
