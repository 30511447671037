import React, { PureComponent } from 'react'
import Styled from 'styled-components'


const Text = Styled.div`
    height: 30px;
    display: flex;
    background: white;
    align-items:center;
    text-align: center;
    justify-content: center;
    padding: 10px 20px;
    border-radius: 10px;
    width: 60px;
    transform: rotate(-90deg);
    box-shadow: 0 10px 15px rgba(0,0,0,0.3);
    user-select: none;
    font-size: 1.5em;
`

const conv = (SECONDS) => {
    var date = new Date(null);
    date.setSeconds(SECONDS); // specify value for SECONDS here
    return date.toISOString().substr(14, 5);
}
export default class Timer extends PureComponent {

    state = {
        value: 0
    }

    start = () => {
        this.removeInterval()

        this.timer = setInterval(() => {
            this.setState({ value: this.state.value + 1 })
        }, 1000)
    }

    componentWillUnmount = () => {
        this.removeInterval()
    }

    removeInterval = () => {
        clearInterval(this.timer)
        this.setState({ value: 0 })
    }


    render() {
        const { value } = this.state
        return (
            <Text>{conv(value)}</Text>
        )
    }
}
